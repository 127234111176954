import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3513 0.946289C17.0981 0.946289 21.7567 5.60495 21.7567 11.3517C21.7567 17.0984 17.0981 21.7571 11.3513 21.7571C5.60458 21.7571 0.945923 17.0984 0.945923 11.3517C0.945923 5.60495 5.60458 0.946289 11.3513 0.946289ZM2.88977 10.4057C3.28052 6.87097 5.83533 3.98842 9.19997 3.11234C7.75588 5.28291 6.88107 7.79192 6.66898 10.4057H2.88977ZM2.88977 12.2976C3.28052 15.8324 5.83533 18.715 9.19997 19.591C7.75588 17.4205 6.88107 14.9115 6.66898 12.2976H2.88977ZM13.5027 19.591C16.8673 18.715 19.4221 15.8324 19.8129 12.2976H16.0337C15.8216 14.9115 14.9468 17.4205 13.5027 19.591ZM19.8129 10.4057H16.0337C15.8216 7.79192 14.9468 5.28291 13.5027 3.11234C16.8673 3.98842 19.4221 6.87097 19.8129 10.4057ZM11.3513 3.3592C12.9308 5.39406 13.8971 7.83924 14.1347 10.4057H8.56797C8.80559 7.83924 9.7719 5.39406 11.3513 3.3592ZM11.3513 19.3442C9.7719 17.3093 8.80559 14.8642 8.56797 12.2976H14.1347C13.8971 14.8642 12.9308 17.3093 11.3513 19.3442Z"
          fill="#DDE3EF"
        />
      </svg>
    </SvgIcon>
  );
}
