import React from "react";
import CompanyGeneralInformations from "@/pages/phishing-simulation/company/CompanyGeneralInformations";
import { GroupsDepartments } from "@/pages/general-settings/commonUserProvisioning/GroupsDepartments/GroupsDepartments";
import { TemplateAttribution } from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/TemplateAttribution";
import Tabs, {
  CurrentTabComponent,
  TabObject,
  TabsContextProvider,
} from "@/components/controls/Tabs";
import StickyHeader from "@/components/StickyHeader";
import { Typography } from "@mui/material";

const tabs: Array<TabObject> = [
  {
    label: "General Informations",
    anchor: "general-informations",
    component: <CompanyGeneralInformations />,
  },
  {
    label: "Groups & Departments",
    anchor: "groups-departments",
    component: <GroupsDepartments />,
  },
  {
    label: "Template Attribution",
    anchor: "template-attribution",
    component: <TemplateAttribution />,
  },
];

const CompanyScan = () => {
  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <StickyHeader height="120px">
        <Typography variant="h2">Company Scan</Typography>
        <Tabs />
      </StickyHeader>
      <CurrentTabComponent />
    </TabsContextProvider>
  );
};

export default CompanyScan;
