import React, { useEffect, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import axios from "axios";
import { extractErrorMessage } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import { EmailTableComponent } from "@/pages/phishing-simulation/setup/EmailTableComponent";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ButtonSpinner } from "@/components/Spinner";

export function WhitelistingScenarios(): React.JSX.Element {
  const [outboundRequest, setOutboundRequest] = useState<boolean>(false);
  const [templates, setTemplatesList] = useState([]);
  const [showTestSent, setShowTestSent] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [errorMessageTest, setErrorMessageTest] = useState<string>("");
  const [isLoadingSendAll, setIsLoadingSendAll] = useState<boolean>(false);
  const { user } = useAuth0();

  const tableDisplayOptions = {
    download: false,
    filter: false,
    pagination: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={() => setShouldRefresh((prev) => !prev)}>
            <RefreshIcon />
          </IconButton>
          <Button
            variant="outlined"
            onClick={sendAllTestEmails}
            disabled={
              templates.length <= 0 || outboundRequest || isLoadingSendAll
            }
          >
            {isLoadingSendAll ? (
              <ButtonSpinner style={{ marginRight: "1rem" }} />
            ) : (
              ""
            )}
            Send all test simulations
          </Button>
        </>
      );
    },
  };

  const sendAllTestEmails = () => {
    setIsLoadingSendAll(true);
    const simulationPromises = templates.map((template) => {
      if (template.template_scenario === "simple_text_email") {
        return new Promise<void>((resolve) =>
          sendTest().finally(() => resolve()),
        );
      } else {
        return new Promise<void>((resolve) =>
          sendTemplate(template.name, template.language__code).finally(() =>
            resolve(),
          ),
        );
      }
    });
    Promise.all(simulationPromises).finally(() => {
      setIsLoadingSendAll(false);
    });
  };

  const sendTest = async () => {
    try {
      setOutboundRequest(true);
      await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/test_email`,
      );
      setShowTestSent(true);
      setErrorMessageTest(null);
      setOutboundRequest(false);
    } catch (error) {
      setShowTestSent(false);
      setErrorMessageTest(extractErrorMessage(error));
      setOutboundRequest(false);
    }
  };

  const sendTemplate = async (
    template_name,
    template_language,
    false_banners = false,
  ) => {
    try {
      setOutboundRequest(true);

      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/test_email_from_template`,
        {
          template_name: template_name,
          template_language: template_language,
          add_banner: false_banners,
        },
      );
      setShowTestSent(true);
      setErrorMessageTest(null);
      setOutboundRequest(false);
      setShouldRefresh((prev) => !prev);
    } catch (error) {
      setShowTestSent(false);
      setErrorMessageTest(extractErrorMessage(error));
      setOutboundRequest(false);
    }
  };

  useEffect(() => {
    const whitelisting_templates_call = async () => {
      const result = await axios.get(
        `${
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION
        }/templates/whitelisting_tests`,
      );
      let templates = [
        {
          attachment: null,
          from_email: "team@mantra.ms",
          from_name: "Mantra Team",
          id: null,
          language__code: "fr",
          name: "Simple text email",
          scenario_template: "",
          send_date: "",
          status: "",
          subject: "Setup test",
          template_scenario: "simple_text_email", // This is used later to determine whether to display a Button or a SendButtonList
        },
      ].concat(result.data.templates);
      setTemplatesList(templates);
    };
    // noinspection JSIgnoredPromiseFromCall
    whitelisting_templates_call();
  }, [shouldRefresh]);

  const additional_columns = [
    {
      name: "send_button",
      label: "",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { style: { textAlign: "center" } };
        },
        customBodyRenderLite: (dataIndex) => {
          const template = templates[dataIndex];
          const sendRealTemplate = () =>
            sendTemplate(template.name, template.language__code);

          return (
            <Button
              disableElevation
              variant="contained"
              disabled={outboundRequest || isLoadingSendAll}
              sx={{ fontWeight: "900", width: "89px" }}
              onClick={
                template.template_scenario === "simple_text_email"
                  ? sendTest
                  : sendRealTemplate
              }
            >
              Send
            </Button>
          );
        },
        customHeadLabelRender: (columnMeta) => {
          return "";
        },
      },
    },
  ];

  return (
    <>
      <DeliveryTestDocumentationText />
      <EmailTableComponent
        data={templates}
        additionalColumns={additional_columns}
        tableDisplayOptions={tableDisplayOptions}
      />

      {showTestSent && (
        <div style={{ color: "green" }} className="mt-3">
          Email successfully sent to <strong>{user.email}</strong>.
        </div>
      )}
      {errorMessageTest && (
        <div style={{ color: "red" }}>{errorMessageTest}</div>
      )}
    </>
  );
}

const DeliveryTestDocumentationText = (): React.JSX.Element => {
  return (
    <Box sx={{ pb: "1rem" }}>
      Once you have set up your domain according to this{" "}
      <Box
        component="span"
        sx={{
          color: "#0000FF",
          fontWeight: 600,
        }}
      >
        <a href="https://www.notion.so/mantrams/Mantra-Setup-Guide-d8db591a14b54dd2a184f2287b917360">
          documentation
        </a>
      </Box>
      .
      <br />
      Check the delivery by sending yourself the templates below. If everything
      is correctly set up, you should be able to receive all the templates below
      in your inbox.
    </Box>
  );
};
