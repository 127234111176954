import { Paper } from "@mui/material";
import React from "react";
import { WhitelistingScenarios } from "@/pages/phishing-simulation/setup/WhitelistingScenarios";
import { WhitelistingTestLog } from "@/pages/phishing-simulation/setup/WhitelistingTestLog";
import { useUserContext } from "@/utils/contexts/UserContext";
import { SendAnySimulation } from "./SendAnySimulation";

const MANTRA_DOMAINS = [
  "mantra.ms",
  "getplexx.com",
  "mantrams.onmicrosoft.com",
  "getplexx.onmicrosoft.com",
];

export function DeliveryTest() {
  const { base_company } = useUserContext();
  const isStaff =
    MANTRA_DOMAINS.filter((domain) => base_company.domain === domain).length >
    0;

  return (
    <Paper sx={{ py: "2rem", px: "1rem" }}>
      <WhitelistingScenarios />
      <WhitelistingTestLog />
      {isStaff ? <SendAnySimulation /> : ""}
    </Paper>
  );
}
