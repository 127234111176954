import React, { PropsWithChildren } from "react";

export const OnlyMantraDiv = (props: PropsWithChildren): React.JSX.Element => {
  return (
    <div
      style={{
        color: "#ffdbdb",
        background:
          "repeating-linear-gradient(-45deg, transparent, transparent 10px, currentColor 10px, currentColor calc(2 * 10px))",
        padding: "1rem",
        borderRadius: "1rem",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div style={{ color: "black", fontWeight: "bold" }}>
          Only visible by Mantra admins
        </div>
      </div>
      {props.children}
    </div>
  );
};
