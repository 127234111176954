import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import LoadingPage from "@/pages/banners/loading-page/LoadingPage";
import axios from "axios";
import { browserLanguage } from "@/pages/banners/utils";
import { LANDING_PAGE_ERROR } from "@/pages/banners/translations";
import DisableThreatNotificationsErrorPage from "@/pages/newthreats/disable-notifications/DisableThreatNotificationsErrorPage";
import DisableNotificationsSuccessPage from "@/pages/newthreats/disable-notifications/DisableNotificationsSuccessPage";

export function DisableThreatNotifications() {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null); // Display an error if not empty
  const [isPending, setPending] = useState(true);
  const browser_language = browserLanguage();
  const translate = LANDING_PAGE_ERROR[browser_language];

  useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();

      try {
        await axios.post(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/threats/disable_notifications",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        setPending(false);
      } catch (error) {
        setError(error);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    f();
  }, [getAccessTokenSilently]);

  let body = null;

  if (isPending && error == null) {
    body = <LoadingPage />;
  } else if (error) {
    body = (
      <DisableThreatNotificationsErrorPage
        message={translate.an_error_has_occurred}
      />
    );
  } else {
    body = <DisableNotificationsSuccessPage />;
  }
  return body;
}
