import React, { useCallback, useMemo, useState } from "react";
import { Card, Grid, Tooltip, Typography } from "@mui/material";
import { CourseStatusChip } from "@/pages/awareness/courses/courses-catalog/CourseStatusChip";
import { CourseCardFooter } from "@/pages/awareness/courses/courses-catalog/CourseCardFooter";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSentryCapture } from "@/utils/sentry";
import { extractErrorMessage, getConfig } from "@/utils/misc";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS } from "@/pages/awareness/courses/utils/translations";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useTranslation } from "react-i18next";
export const CourseCardsContainer = ({
  isCourseCatalogAdmin,
  learningUserInfos,
  course,
  isPublicWebchat,
  onClickAssignment,
}) => {
  const navigate = useNavigate();
  const { capture } = useSentryCapture();
  const { getAccessTokenSilently } = useAuth0();
  const isCurrentCourse = learningUserInfos.current_course === course.id;
  const [showSpinner, setShowSpinner] = useState(false);
  const snackbar = useSnackbar();
  const { t } = useTranslation("translation", {
    lng: learningUserInfos.language,
  });
  const canFollowCourse =
    learningUserInfos.bot_installed ||
    isPublicWebchat ||
    learningUserInfos.isDemoAcme;
  const user_not_setup_translation = useMemo(
    () =>
      USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS[learningUserInfos.language] ||
      USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS["en"],
    [learningUserInfos.language],
  );
  const runPublicCourse = useCallback(async () => {
    setShowSpinner(true);
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/public/webchat/course/${
          course.id
        }/start`,
        { language: "fr" },
      );
      setShowSpinner(false);
      navigate(`/demo/webchat/${response.data.id}`);
    } catch (error) {
      capture(error);
    }
  }, [capture, course.id, navigate]);

  const toWebChat = useCallback(() => navigate("/webchat"), [navigate]);

  const resumeCourse = useCallback(async () => {
    setShowSpinner(true);
    try {
      if (learningUserInfos.messaging_platform === "webchat") {
        toWebChat();
      } else {
        await axios.post(
          `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/user/notify-me`,
          {},
          await getConfig(getAccessTokenSilently),
        );
      }
      setShowSpinner(false);
    } catch (error) {
      if (
        error.response?.data === "Prefer a redirection to the webchat platform"
      ) {
        toWebChat();
      } else {
        capture(error);
        setShowSpinner(false);
        snackbar.showMessage(extractErrorMessage(error));
      }
    }
  }, [
    capture,
    getAccessTokenSilently,
    learningUserInfos.messaging_platform,
    snackbar,
    toWebChat,
  ]);

  const startCourse = useCallback(async () => {
    setShowSpinner(true);
    try {
      const config = await getConfig(getAccessTokenSilently);
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/courses/${
          course.id
        }/send`,
        { language: learningUserInfos.language },
        config,
      );
      const messaging_platform = learningUserInfos.messaging_platform;
      setShowSpinner(false);
      if (messaging_platform === "webchat") {
        return toWebChat();
      }
    } catch (error) {
      capture(error);
      setShowSpinner(false);
      snackbar.showMessage(extractErrorMessage(error));
    }
  }, [
    capture,
    course.id,
    getAccessTokenSilently,
    learningUserInfos.language,
    learningUserInfos.messaging_platform,
    snackbar,
    toWebChat,
  ]);

  const onClickStartCourse = () => {
    if (isPublicWebchat) {
      return runPublicCourse();
    }
    if (isCurrentCourse) {
      return resumeCourse();
    }
    return startCourse();
  };

  return (
    <Card>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography
            variant={"h2"}
            sx={{ paddingLeft: "15px", paddingTop: "20px", fontSize: "24px" }}
          >
            {course.label_name}
            {!isPublicWebchat && !isCourseCatalogAdmin && (
              <CourseStatusChip
                course={course}
                learningUserInfo={learningUserInfos}
              />
            )}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            sx={{ paddingLeft: "18px", fontSize: "14px", color: "#64748B" }}
          >
            {course.description}{" "}
          </Typography>
        </Grid>
        {(!isCourseCatalogAdmin || isPublicWebchat) && (
          <>
            <Grid item sx={{ paddingRight: "30px" }}>
              <Tooltip
                title={canFollowCourse ? "" : user_not_setup_translation}
              >
                <span>
                  <LoadingButton
                    disabled={!canFollowCourse}
                    variant={isCurrentCourse ? "outlined" : "contained"}
                    loading={showSpinner}
                    sx={{ width: "140px", fontWeight: "800", fontSize: "14px" }}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onClickStartCourse();
                    }}
                  >
                    {isCurrentCourse ? t("Resume course") : t("Start course")}
                  </LoadingButton>
                </span>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
      <CourseCardFooter
        learningUserInfos={learningUserInfos}
        course={course}
        isPublicWebchat={isPublicWebchat}
        isCourseCatalogAdmin={isCourseCatalogAdmin}
        onClickAssignment={onClickAssignment}
      />
    </Card>
  );
};
