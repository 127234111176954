import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Switch, Typography } from "@mui/material";
import {
  CategoryTitle,
  Permission,
  PermissionCategory,
  UserWithPermissions,
} from "@/pages/general-settings/permissions-attribution/permissions";
import { usePermissionService } from "@/pages/general-settings/permissions-attribution/permissionService";
import { ButtonSpinner } from "@/components/Spinner";

function PermissionsHeader(props: { user: UserWithPermissions }) {
  return (
    <Box sx={{ padding: "1rem 1.5rem 0.5rem" }}>
      <h4>Edit permissions of:</h4>
      <span>{props.user.email}</span>
    </Box>
  );
}

function GrantAllPermissionsSwitch({
  selectedPermissions,
  setAllPermissions,
  removeAllPermissions,
}: {
  selectedPermissions: Set<Permission>;
  setAllPermissions: () => void;
  removeAllPermissions: () => void;
}) {
  const allSelected = Permission.allEditable().every((p) =>
    selectedPermissions.has(p),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "end",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>
        {allSelected ? "Remove" : "Grant"} all permissions
      </Typography>
      <Switch
        checked={allSelected}
        onChange={allSelected ? removeAllPermissions : setAllPermissions}
      />
    </Box>
  );
}

function PermissionByCategoryBlock({
  selectedPermissions,
  category,
  addPermission,
  removePermission,
}: {
  selectedPermissions: Set<Permission>;
  category: PermissionCategory;
  addPermission: (p: Permission) => void;
  removePermission: (p: Permission) => void;
}) {
  const relevantPermissions = Permission.allEditable().filter(
    (p) => p.category === category,
  );

  return (
    <div>
      <h5 style={{ padding: "1rem 1.5rem 0" }}>
        {CategoryTitle.get(category)}
      </h5>
      {relevantPermissions.map((permission) => (
        <PermissionDetails
          key={permission.code}
          permission={permission}
          switchValue={selectedPermissions.has(permission)}
          addPermission={addPermission}
          removePermission={removePermission}
        />
      ))}
    </div>
  );
}

function PermissionDetails({
  permission,
  switchValue,
  addPermission,
  removePermission,
}: {
  permission: Permission;
  switchValue: boolean;
  addPermission: (p: Permission) => void;
  removePermission: (p: Permission) => void;
}) {
  return (
    <Box
      sx={{
        position: "relative",
        "&:hover": { backgroundColor: "#D9D9D949" },
        padding: "0.5rem 1.5rem 0.3rem",
      }}
    >
      <Typography sx={{ marginBottom: "0.2rem" }}>{permission.name}</Typography>
      <Typography sx={{ fontStyle: "italic", fontSize: "0.7rem" }}>
        {permission.description}
      </Typography>
      <Box sx={{ position: "absolute", top: 0, right: "1rem" }}>
        <Switch
          size="small"
          checked={switchValue}
          onChange={(input) =>
            input.target.checked
              ? addPermission(permission)
              : removePermission(permission)
          }
        />
      </Box>
    </Box>
  );
}

function PermissionsFooter({
  cancel,
  confirm,
  loading,
}: {
  cancel: () => void;
  confirm: () => void;
  loading: boolean;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem",
      }}
    >
      <Button variant="outlined" onClick={cancel}>
        Back
      </Button>
      <Button
        variant="contained"
        onClick={confirm}
        startIcon={loading ? <ButtonSpinner /> : null}
        disabled={loading}
      >
        Confirm
      </Button>
    </Box>
  );
}

function useSelectedPermissions(permissions: Array<Permission>) {
  const initialEditablePermissions = Permission.allEditable().filter((p) =>
    permissions.includes(p),
  );
  const [selectedPermissions, setSelectedPermissions] = useState(
    new Set(initialEditablePermissions),
  );

  function addPermission(permission: Permission) {
    setSelectedPermissions((prevState) => {
      const newState = new Set(prevState);
      newState.add(permission);
      return newState;
    });
  }

  function removePermission(permission: Permission) {
    setSelectedPermissions((prevState) => {
      const newState = new Set(prevState);
      newState.delete(permission);
      return newState;
    });
  }

  function setAllPermissions() {
    setSelectedPermissions(new Set(Permission.allEditable()));
  }

  function removeAllPermissions() {
    setSelectedPermissions(new Set());
  }

  return {
    selectedPermissions,
    addPermission,
    removePermission,
    setAllPermissions,
    removeAllPermissions,
  };
}

export default function EditPermission({
  user,
  closeDrawer,
}: {
  user: UserWithPermissions;
  closeDrawer: () => void;
}) {
  const {
    selectedPermissions,
    setAllPermissions,
    removeAllPermissions,
    addPermission,
    removePermission,
  } = useSelectedPermissions(user.permissions);
  const { updateUserPermissions, updateIsLoading, updateIsSuccess } =
    usePermissionService();

  function submitNewPermissions() {
    updateUserPermissions(user, Array.from(selectedPermissions));
  }

  useEffect(() => {
    if (updateIsSuccess) {
      closeDrawer();
    }
  }, [updateIsSuccess, closeDrawer]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "30vw",
        height: "100%",
      }}
    >
      <PermissionsHeader user={user} />
      <Divider />
      <GrantAllPermissionsSwitch
        selectedPermissions={selectedPermissions}
        setAllPermissions={setAllPermissions}
        removeAllPermissions={removeAllPermissions}
      />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        {Object.values(PermissionCategory).map((category) => (
          <PermissionByCategoryBlock
            selectedPermissions={selectedPermissions}
            category={category}
            addPermission={addPermission}
            removePermission={removePermission}
          />
        ))}
      </Box>
      <Divider />
      <PermissionsFooter
        cancel={closeDrawer}
        confirm={submitNewPermissions}
        loading={updateIsLoading}
      />
    </Box>
  );
}
