import React from "react";
import { SvgIcon } from "@mui/material";

export default function (props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="5.40534" cy="5.40534" r="5.40534" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.40536 -0.000124088C2.42004 -0.000124088 -4.43698e-05 2.41996 -4.43698e-05 5.40528C-4.43698e-05 8.3906 2.42004 10.8107 5.40536 10.8107C8.39068 10.8107 10.8108 8.3906 10.8108 5.40528C10.8108 2.41996 8.39068 -0.000124088 5.40536 -0.000124088ZM7.96414 4.27855C8.15604 4.08665 8.15604 3.77551 7.96414 3.58361C7.77223 3.3917 7.46109 3.3917 7.26919 3.58361L4.66826 6.18454L3.54153 5.05781C3.34963 4.8659 3.03849 4.8659 2.84659 5.05781C2.65468 5.24971 2.65468 5.56085 2.84659 5.75275L4.32079 7.22696C4.51269 7.41886 4.82383 7.41886 5.01573 7.22696L7.96414 4.27855Z"
          fill="#65C58B"
        />
      </svg>
    </SvgIcon>
  );
}
