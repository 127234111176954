import { Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSimulationResource } from "@/utils/ResourceGet";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";

const ENDPOINT =
  "provisioning/current_company/office_provision_users_without_license";

export function UsersWithNoLicenseCheckbox() {
  const snackbar = useSnackbar();

  const [{ enabled: optionEnabled }, readError] = useSimulationResource(
    ENDPOINT,
    false,
  );

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(optionEnabled);
  }, [optionEnabled]);

  async function onChange(): Promise<void> {
    const initialCheckValue = !!checked;
    const targetCheckValue = !checked;
    try {
      setChecked(targetCheckValue);
      await axios.put(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/${ENDPOINT}`,
        { enabled: targetCheckValue },
      );
      snackbar.showMessage(
        `Users without license will ${
          targetCheckValue === true ? "now" : "no longer"
        } be provisioned.`,
      );
    } catch {
      snackbar.showMessage("Could not update value.");
      setChecked(initialCheckValue);
    }
  }

  return (
    <div>
      <Tooltip
        title="If used without Whitelisted Groups and Blacklisted Users,
    this will probably provision too many users from your AD."
        placement="right"
        arrow
      >
        <span>
          <input
            type="checkbox"
            id="usersWithoutLicenseEnabled"
            checked={checked}
            onChange={onChange}
            disabled={readError}
          />
          <label htmlFor="usersWithoutLicenseEnabled">
            &nbsp;&nbsp;&nbsp;Provision Users without License
          </label>
        </span>
      </Tooltip>
      {readError !== null && (
        <Typography style={{ color: "red" }}>
          Could not retrieve data.
        </Typography>
      )}
    </div>
  );
}
