import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1351 7.56728V4.72944L17.9729 1.8916L18.9189 3.78349L20.8108 4.72944L17.9729 7.56728H15.1351ZM15.1351 7.56728L11.3513 11.351M20.8108 11.3511C20.8108 16.5754 16.5757 20.8105 11.3514 20.8105C6.12705 20.8105 1.89191 16.5754 1.89191 11.3511C1.89191 6.12675 6.12705 1.8916 11.3514 1.8916M16.0811 11.3511C16.0811 13.9632 13.9635 16.0808 11.3514 16.0808C8.73921 16.0808 6.62164 13.9632 6.62164 11.3511C6.62164 8.7389 8.73921 6.62133 11.3514 6.62133"
          stroke="#DDE3EF"
          strokeWidth="1.89189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
