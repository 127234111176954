import css from "@/pages/banners/report-as-threat/ReportSuccessPage.module.css";
import { LANDING_PAGE_DISABLE_THREAT_NOTIFICATIONS } from "@/pages/banners/translations";
import { browserLanguage } from "@/pages/banners/utils";

const DisableNotificationsSuccessPage = () => {
  const browser_language = browserLanguage();
  const translate = LANDING_PAGE_DISABLE_THREAT_NOTIFICATIONS[browser_language];
  return (
    <div className={css.container}>
      <div className={css.message_container}>
        <img
          src="/media/logos/logo_mantra_loading_screen.png"
          alt="Mantra logo"
        />
        <p className={css.message}>{translate.notification_disabled}</p>
      </div>
      <div className={css.footer}>
        <p>
          {translate.footer} <a href="mailto:team@mantra.ms">team@mantra.ms</a>
        </p>
      </div>
    </div>
  );
};

export default DisableNotificationsSuccessPage;
