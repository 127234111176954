import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import {
  AuditData,
  PreviewData,
} from "@/pages/phishing-simulation/audit/Audit";

export enum AuditStep {
  DEFINE = "DEFINE",
  PREVIEW = "PREVIEW",
  NEWLY_VALIDATED = "NEWLY_VALIDATED",
  ALREADY_VALIDATED = "ALREADY_VALIDATED",
}

interface RenderStepDefineProps {
  auditData: AuditData;
  handleDateChange: (
    field: "start_date" | "end_date",
  ) => (date: Dayjs | null) => void;
  handleSimulationsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePreviewAudit: () => void;
  isStartDateValid: boolean;
  isDateRangeValid: boolean;
  isFormValid: boolean;
}

interface RenderStepPreviewProps {
  previewData: PreviewData[];
  handleCancel: () => void;
  handleValidateAudit: () => void;
}

interface RenderStepValidatedProps {
  step: AuditStep.NEWLY_VALIDATED | AuditStep.ALREADY_VALIDATED;
  auditData: AuditData;
  handleCancel: () => void;
  handleViewResults: () => void;
}

export const renderStepDefine = ({
  auditData,
  handleDateChange,
  handleSimulationsChange,
  handlePreviewAudit,
  isStartDateValid,
  isDateRangeValid,
  isFormValid,
}: RenderStepDefineProps) => (
  <Stack spacing={3}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Start date"
        value={auditData.start_date}
        onChange={handleDateChange("start_date")}
        format="YYYY/MM/DD"
        slotProps={{
          textField: {
            helperText: isStartDateValid
              ? "When the first simulation can be sent to a user"
              : "Start date must be in the future",
            fullWidth: true,
            error: !isStartDateValid,
            sx: { width: "300px" },
          },
        }}
      />
      <DatePicker
        label="End date"
        value={auditData.end_date}
        onChange={handleDateChange("end_date")}
        format="YYYY/MM/DD"
        slotProps={{
          textField: {
            helperText: isDateRangeValid
              ? "When the last simulation can be sent to a user"
              : "End date must be greater than or equal to start date",
            fullWidth: true,
            error: !isDateRangeValid,
            sx: { width: "300px" },
          },
        }}
      />
    </LocalizationProvider>
    <TextField
      label="Simulation(s) per user"
      type="number"
      value={auditData.simulations_per_user}
      onChange={handleSimulationsChange}
      fullWidth
      helperText="The number of simulations each user will receive"
      inputProps={{ min: 1 }}
      sx={{ width: "300px" }}
    />
    <Typography color="text.secondary">
      Mantra will automatically pick and randomize templates based on the
      selection you made in the Template attribution. Click on Preview audit to
      see what simulations will be sent.
    </Typography>
    <Box display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        onClick={handlePreviewAudit}
        disabled={!isFormValid}
      >
        Preview audit
      </Button>
    </Box>
  </Stack>
);

export const renderStepPreview = ({
  previewData,
  handleCancel,
  handleValidateAudit,
}: RenderStepPreviewProps) => (
  <Stack
    spacing={3}
    sx={{ height: "100%", display: "flex", flexDirection: "column" }}
  >
    <TableContainer sx={{ flexGrow: 1, overflow: "auto" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Recipient</TableCell>
            <TableCell>Template title</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell>Language</TableCell>
            <TableCell>Payload</TableCell>
            <TableCell>Sending date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previewData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.recipient}</TableCell>
              <TableCell>{row.template_title}</TableCell>
              <TableCell>{row.tag}</TableCell>
              <TableCell>{row.language}</TableCell>
              <TableCell>{row.payload}</TableCell>
              <TableCell>{row.sending_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {previewData.length === 0 && (
      <Typography color="error" align="center">
        Error: No simulations could be scheduled.
        <br />
        Please check your company scan settings (Phishing Simulation {">"}{" "}
        Company Scan {">"} Template attribution) and assign more tags.
        <br />
        Note that some templates aren't available in trial mode.
      </Typography>
    )}
    <Box display="flex" justifyContent="space-between" mt={2}>
      <Button variant="outlined" onClick={handleCancel}>
        Change parameters
      </Button>
      <Tooltip
        title={
          previewData.length === 0 ? "Cannot schedule an empty trial run" : ""
        }
      >
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleValidateAudit}
            disabled={previewData.length === 0}
          >
            Schedule audit
          </Button>
        </span>
      </Tooltip>
    </Box>
  </Stack>
);

export const renderStepValidated = ({
  step,
  auditData,
  handleCancel,
  handleViewResults,
}: RenderStepValidatedProps) => (
  <Paper
    elevation={3}
    sx={{ p: 4, mt: 4, width: "100%", maxWidth: 600, mx: "auto" }}
  >
    <Stack spacing={4} alignItems="center">
      <Typography variant="h4" align="center">
        {step === AuditStep.NEWLY_VALIDATED
          ? "Audit successfully planned!"
          : `Audit is planned, come back on start date (${auditData.start_date?.format(
              "YYYY-MM-DD",
            )}) to see the first results.`}
      </Typography>
      <Box display="flex" justifyContent="space-between" width="100%" mt={4}>
        <Button variant="contained" color="error" onClick={handleCancel}>
          Cancel audit
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewResults}
          disabled={step === AuditStep.ALREADY_VALIDATED}
        >
          View results
        </Button>
      </Box>
    </Stack>
  </Paper>
);
