import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_88_576)">
          <path
            d="M11.3511 8.51389V12.2977M11.3511 16.0815H11.3606M10.0413 3.68174L2.261 17.1204C1.82946 17.8658 1.61368 18.2385 1.64557 18.5444C1.67339 18.8112 1.81317 19.0537 2.03013 19.2114C2.27888 19.3923 2.70953 19.3923 3.57084 19.3923H19.1314C19.9927 19.3923 20.4234 19.3923 20.6721 19.2114C20.8891 19.0537 21.0289 18.8112 21.0567 18.5444C21.0886 18.2385 20.8728 17.8658 20.4413 17.1204L12.661 3.68173C12.231 2.93901 12.016 2.56765 11.7355 2.44293C11.4908 2.33413 11.2115 2.33413 10.9668 2.44293C10.6863 2.56765 10.4713 2.93901 10.0413 3.68174Z"
            stroke="#7566FE"
            strokeWidth="1.89189"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_88_576">
            <rect width="22.7027" height="22.7027" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
