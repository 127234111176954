import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0792 8.51235C16.0792 8.02825 15.8945 7.54415 15.5251 7.17479C15.1558 6.80542 14.6716 6.62074 14.1875 6.62074M14.1875 14.1874C17.3217 14.1874 19.8625 11.6466 19.8625 8.5124C19.8625 5.37819 17.3217 2.8374 14.1875 2.8374C11.0533 2.8374 8.51253 5.37819 8.51253 8.5124C8.51253 8.77126 8.52986 9.02606 8.56342 9.27573C8.61863 9.68635 8.64623 9.89167 8.62765 10.0216C8.60829 10.1569 8.58365 10.2298 8.51696 10.3491C8.45294 10.4637 8.34011 10.5765 8.11445 10.8021L3.28077 15.6358C3.11719 15.7994 3.03539 15.8812 2.9769 15.9767C2.92504 16.0613 2.88683 16.1535 2.86366 16.25C2.83752 16.3589 2.83752 16.4746 2.83752 16.7059V18.3491C2.83752 18.8788 2.83752 19.1436 2.94061 19.346C3.03129 19.5239 3.17599 19.6686 3.35396 19.7593C3.55628 19.8624 3.82114 19.8624 4.35086 19.8624H6.62086V17.9707H8.51253V16.0791H10.4042L11.8978 14.5855C12.1234 14.3598 12.2363 14.247 12.3508 14.183C12.4701 14.1163 12.543 14.0916 12.6784 14.0723C12.8083 14.0537 13.0136 14.0813 13.4242 14.1365C13.6739 14.1701 13.9287 14.1874 14.1875 14.1874Z"
          stroke="#7566FE"
          strokeWidth="1.38889"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
