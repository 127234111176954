import React from "react";
import Body from "@/pages/threatsng/components/detailview/overview/Body";

const OverviewItem = ({ name, value }: { name: string; value: string }) => {
  return (
    <>
      <div>
        <strong>{name}</strong>: {value}
      </div>
    </>
  );
};

const DkimInfo = ({ status }: { status: DkimStatus }) => {
  let color: string;
  switch (status) {
    case "missing":
      color = "danger";
      break;
    case "pass":
      color = "success";
      break;
    default:
      color = "light";
  }

  return <div className={`badge badge-${color}`}>Dkim : {status}</div>;
};

const Overview = ({ threatDetails }: { threatDetails: ThreatDetails }) => {
  const attachments = threatDetails.attachments.map(
    (attachment: Attachment, i: number) => (
      <li key={i}>
        <strong>{attachment.name}</strong>
        {attachment.size ? ` (${formatBytes(attachment.size)})` : ""}
      </li>
    ),
  );

  console.log(threatDetails.date);

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <DkimInfo key="dkim_status" status={threatDetails.dkimStatus} />
      </div>
      <OverviewItem
        key="report_date"
        name="Reported at"
        value={new Date(Date.parse(threatDetails.report_date)).toLocaleString(
          undefined,
        )}
      />
      <OverviewItem key="from" name="From" value={threatDetails.from_} />
      <OverviewItem
        key="subject"
        name="Subject"
        value={threatDetails.subject}
      />
      <OverviewItem
        key="recipient"
        name="Recipient"
        value={threatDetails.recipient}
      />
      <OverviewItem
        key="date"
        name="Received at"
        value={new Date(Date.parse(threatDetails.date)).toLocaleString(
          undefined,
        )}
      />
      {attachments.length > 0 && (
        <div>
          <strong>Attachments:</strong>
          <ul>{attachments}</ul>
        </div>
      )}
      <Body
        key={threatDetails.id}
        body={threatDetails.body}
        isExpanded={threatDetails.source !== "system"}
      />
    </>
  );
};

function formatBytes(bytes: number, decimals: number = 2): string {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default Overview;
