import React from "react";
import { Checkbox, TextField, Autocomplete, Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

type TemplateAttributionSelectorProps = {
  saasList: {
    id: number;
    name: string;
  };
  companySaasList: {
    id: number;
    name: string;
  };
  onChange: (newSaasList) => void;
};

const TemplateAttributionSelector = ({
  saasList,
  companySaasList,
  onChange,
}: TemplateAttributionSelectorProps) => {
  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        id="global_company_saas"
        options={saasList}
        disableCloseOnSelect
        onChange={(event, newSaasList) => {
          onChange(newSaasList); // Update company saas
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={companySaasList}
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => (
            <Chip
              style={{ width: "auto", alignItems: "center" }}
              label={option.name}
              deleteIcon={<CancelIcon />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="" placeholder="" />
        )}
      />
    </div>
  );
};

export default TemplateAttributionSelector;
