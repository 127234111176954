/* eslint-disable no-restricted-imports */

import { Tabs as MuiTabs, Tab } from "@mui/material";
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";

export type TabObject = {
  anchor: string;
  label: string;
  component: React.ReactNode;
};

type TabContext = {
  tabs: Array<TabObject>;
  currentTabIndex: number | undefined;
  _setCurrentTabIndex: (tabIndex: number) => void;
  currentTabComponent: React.ReactNode;
};

export type TabValues = Omit<
  TabContext,
  "currentTabIndex" | "_setCurrentTabIndex" | "currentTabComponent"
>;

type TabsContextProviderProps = {
  children: React.ReactNode;
  value: TabValues;
  defaultTabIndex?: number | undefined;
};

const TabsContext = createContext<TabContext>({
  tabs: [],
  currentTabIndex: 0,
  _setCurrentTabIndex: () => undefined,
  currentTabComponent: <></>,
});

/*
 * Context provider for the Tabs component
 */
export const TabsContextProvider: React.FC<TabsContextProviderProps> = ({
  children,
  value,
}) => {
  const [currentTabIndex, _setCurrentTabIndex] = useState<number | undefined>(
    0,
  );
  const [currentTabComponent, _setCurrentTabComponent] =
    useState<React.ReactNode>(<></>);

  useEffect(() => {
    if (currentTabIndex == null) {
      return;
    }
    _setCurrentTabComponent(value.tabs?.[currentTabIndex]?.component);
  }, [value.tabs, currentTabIndex, _setCurrentTabComponent]);

  return (
    <TabsContext.Provider
      value={{
        currentTabIndex,
        _setCurrentTabIndex,
        currentTabComponent,
        ...value,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

/*
 * Hook to retrieve hook tabs context
 */
export function useTabsContext() {
  const context = useContext(TabsContext);
  if (context === undefined) {
    throw new Error(
      "useTabsContext should be used only into a TabsContextProvider",
    );
  }
  return context;
}

/*
 * Provide an abstraction around MUI tabs,
 * - handle the state automatically
 * - add anchor to links
 * - uses context for more flexibility
 *
 * You should put this component inside a TabsContextProvider
 */
const Tabs: React.FC = () => {
  const { tabs, currentTabIndex, _setCurrentTabIndex } = useTabsContext();
  const { hash } = useLocation();

  const changeTab = useCallback(
    (_: any, newValue: number) => {
      window.location.hash = tabs[newValue].anchor;
      _setCurrentTabIndex(newValue);
    },
    [tabs, _setCurrentTabIndex],
  );

  useEffect(() => {
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].anchor === hash.slice(1)) {
        changeTab(undefined, i);
        break;
      }
    }
  }, [tabs, _setCurrentTabIndex, hash, changeTab]);

  if (currentTabIndex == null) {
    return <></>;
  }

  return (
    <MuiTabs
      indicatorColor="primary"
      textColor="inherit"
      component="div"
      className="builder-tabs"
      value={currentTabIndex}
      onChange={changeTab}
    >
      {tabs.map((tab) => (
        <Tab key={tab.anchor} label={tab.label} sx={{ cursor: "pointer" }} />
      ))}
    </MuiTabs>
  );
};

export const CurrentTabComponent = () => {
  const { currentTabComponent } = useTabsContext();
  return currentTabComponent;
};

export default Tabs;
