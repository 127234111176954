import React from "react";
import { SvgIcon } from "@mui/material";

export default function () {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0808 1.02921C11.2602 1.00328 11.4425 1.00328 11.6219 1.02921C11.8287 1.0591 12.0212 1.1318 12.174 1.18952L12.2149 1.20496L17.4105 3.15315C17.9949 3.37134 18.5081 3.56294 18.9033 3.91126C19.2491 4.21597 19.5154 4.6003 19.6793 5.03103C19.8667 5.52341 19.8659 6.07122 19.865 6.69502L19.8649 11.3508C19.8649 14.0247 18.4107 16.2539 16.8387 17.8915C15.2563 19.5401 13.4457 20.7045 12.4883 21.2631L12.4495 21.2858C12.2748 21.3884 12.048 21.5215 11.748 21.5859C11.5026 21.6385 11.2001 21.6385 10.9547 21.5859C10.6547 21.5215 10.4279 21.3884 10.2532 21.2858L10.2144 21.2631C9.25703 20.7045 7.44645 19.5401 5.86398 17.8915C4.29204 16.2539 2.83784 14.0247 2.83784 11.3508L2.83772 6.69502C2.83681 6.07123 2.83602 5.52341 3.02338 5.03103C3.18729 4.6003 3.45363 4.21597 3.79939 3.91126C4.19462 3.56294 4.70784 3.37134 5.29223 3.15315L10.4878 1.20496L10.5287 1.18953C10.6815 1.1318 10.874 1.0591 11.0808 1.02921ZM15.331 9.18202C15.7004 8.8126 15.7004 8.21366 15.331 7.84425C14.9616 7.47483 14.3627 7.47483 13.9933 7.84425L10.4054 11.4321L9.18238 10.2091C8.81297 9.8397 8.21403 9.8397 7.84462 10.2091C7.4752 10.5785 7.4752 11.1775 7.84462 11.5469L9.73651 13.4388C10.1059 13.8082 10.7049 13.8082 11.0743 13.4388L15.331 9.18202Z"
          fill="#7566FE"
        />
        <circle cx="18.1621" cy="18.1619" r="5.67561" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1621 12.4862C15.0275 12.4862 12.4864 15.0273 12.4864 18.1619C12.4864 21.2965 15.0275 23.8376 18.1621 23.8376C21.2967 23.8376 23.8378 21.2965 23.8378 18.1619C23.8378 15.0273 21.2967 12.4862 18.1621 12.4862ZM20.8488 16.9788C21.0503 16.7773 21.0503 16.4506 20.8488 16.2491C20.6473 16.0476 20.3206 16.0476 20.1191 16.2491L17.3881 18.9801L16.2051 17.797C16.0036 17.5955 15.6769 17.5955 15.4754 17.797C15.2739 17.9985 15.2739 18.3252 15.4754 18.5267L17.0233 20.0746C17.2248 20.2761 17.5515 20.2761 17.753 20.0746L20.8488 16.9788Z"
          fill="#65C58B"
        />
      </svg>
    </SvgIcon>
  );
}
