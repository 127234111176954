import { useLearningResource } from "@/utils/ResourceGet";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import BootstrapStyleSwitch from "@/components/controls/BootstrapStyleSwitch";

const ENDPOINT = "company/send_onboarding_new_users_status";
const TRY_ONBOARDING_ENDPOINT = "admin/send_onboarding_email";
export const SendOnboardings = () => {
  const snackbar = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [{ enabled: optionEnabled }, readError] = useLearningResource(
    ENDPOINT,
    false,
  );

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(optionEnabled);
  }, [optionEnabled]);

  async function onChange(): Promise<void> {
    const initialCheckValue = !!checked;
    const targetCheckValue = !checked;
    try {
      setChecked(targetCheckValue);
      await axios.put(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/${ENDPOINT}`,
        { enabled: targetCheckValue },
      );
      snackbar.showMessage(
        `New users will ${
          targetCheckValue === true ? "" : "not"
        } receive onboarding emails.`,
      );
    } catch {
      snackbar.showMessage("Could not update value.");
      setChecked(initialCheckValue);
    }
  }

  async function sendExampleOnboardingEmail(): Promise<void> {
    try {
      setButtonLoading(true);
      await axios.post(
        `${
          import.meta.env.VITE_APP_ENDPOINT_LEARNING
        }/${TRY_ONBOARDING_ENDPOINT}`,
      );
      snackbar.showMessage("Email sent.");
    } catch (e) {
      snackbar.showMessage("Error: email could not be sent.");
    }
    setButtonLoading(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Typography variant="h6">
              Send onboarding emails to new users &nbsp;
            </Typography>
            <div style={{ paddingTop: "4px" }}>
              <BootstrapStyleSwitch
                label=""
                id="sendOnboardings"
                checked={checked}
                onChange={onChange}
                disabled={readError}
              />
            </div>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <Typography>
            When activated new employees will receive an email that explains how
            Mantra Awareness courses work.
          </Typography>
          {readError !== null && (
            <Typography style={{ color: "red" }}>
              Could not retrieve data.
            </Typography>
          )}
          <Button
            variant="contained"
            sx={{ marginTop: "1rem" }}
            onClick={sendExampleOnboardingEmail}
            disabled={buttonLoading}
          >
            Send example onboarding email to myself
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
