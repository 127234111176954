export const COURSE_ACTION_TRANSLATIONS = {
  fr: {
    edit: "Éditer le cours",
    view: "Voir le cours",
    ongoing: "Reprendre le cours",
    fresh: "Commencer le cours",
    assign: "Gérer les assignations",
  },
  en: {
    edit: "Edit Course",
    view: "View Course",
    ongoing: "Resume course",
    fresh: "Send course to myself",
    assign: "Manage subscriptions",
  },
};

export const EMPTY_COURSE_CATALOG_TRANSLATIONS = {
  fr: {
    first_line: "Aucun cours n'est souscrit pour le moment.",
    second_line:
      "Rapprochez vous de votre administrateur pour plus d'information.",
  },
  en: {
    first_line: "There are no subscribed courses for now.",
    second_line: "Reach out to your administrator for more information.",
  },
  it: {
    first_line: "Per il momento non sono presenti iscrizioni a corsi.",
    second_line: "Rivolgiti all'amministratore per maggiori informazioni.",
  },
  de: {
    first_line: "Zurzeit sind keine Kurse abonniert.",
    second_line:
      "Wenden Sie sich für weitere Informationen an Ihren Administrator.",
  },
  es: {
    first_line: "De momento, no hay cursos suscritos.",
    second_line:
      "Póngase en contacto con su administrador si desea más información.",
  },
};

export const USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS = {
  fr: "Il semble que votre compte ne soit pas encore entièrement configuré.",
  en: "It looks like your account is not fully set up yet.",
  it: "Sembra che il tuo account non sia ancora completamente configurato.",

  de: "Es scheint, dass Ihr Konto noch nicht vollständig eingerichtet ist.",

  es: "Parece que su cuenta no está completamente configurada aún.",
};

export const COURSE_CARDS_TRANSLATIONS = {
  fr: {
    custom: "Cours personnalisé",
    mantra: "Cours Mantra",
    tooltip: {
      companySubscribed: "Assigné à l'entreprise",
      departmentsSubscribed: "Assigné à certains départements",
    },
  },
  en: {
    custom: "Custom course",
    mantra: "Provided by Mantra",
    tooltip: {
      companySubscribed: "Subscribed to the whole company",
      departmentsSubscribed: "Subscribed to some departments",
    },
  },
};

export const COURSE_SUBSCRIPTION_MODAL_TRANSLATIONS = {
  fr: {
    back: "Retour",
    targets: {
      subscribe: "Assigner",
      unsubscribe: "Désassigner",
      manageSubscriptions: "Changer l'assignation",
      title: "Choix des cibles",
      company: {
        title: "Entreprise",
      },
      departments: {
        title: "Départements",
      },
    },
    departments: {
      title: "Sélectionner les départements",
      sections: {
        subscribed: {
          title: "Départements assignés",
          description: "Cliques sur un département pour le désassigner.",
        },
        unsubscribed: {
          title: "Départements non assignés",
          description: "Cliques sur un département pour l'assigner.",
        },
      },
    },
    snackbars: {
      ceoSubscribeError:
        "Un CEO doit être renseigné pour pouvoir souscrire au cours",
      subscribeError: "Échec de l'assignation.",
      unsubscribeError: "Échec de la désassignation.",
      company: {
        subscribed: "Cours assigné à l'entreprise.",
        unsubscribed: "Cours désassigné pour l'entreprise.",
      },
      departments: {
        subscribed: "Cours assigné au département séléctionné.",
        unsubscribed: "Cours désassigné pour le département séléctionné.",
      },
    },
  },
  en: {
    back: "Back",
    targets: {
      subscribe: "Subscribe",
      unsubscribe: "Unsubscribe",
      manageSubscriptions: "Manage subscriptions",
      title: "Choose targets",
      company: {
        title: "Company",
      },
      departments: {
        title: "Departments",
      },
    },
    departments: {
      title: "Select departments",
      sections: {
        subscribed: {
          title: "Subscribed departments",
          description: "Click a department to unsubscribe it.",
        },
        unsubscribed: {
          title: "Unsubscribed departments",
          description: "Click a department to subscribe it.",
        },
      },
    },
    snackbars: {
      ceoSubscribeError: "CEO required to subscribe to the course",
      subscribeError: "Subscription failed.",
      unsubscribeError: "Unsubscription failed.",
      company: {
        subscribed: "Course subscribed for the company.",
        unsubscribed: "Course unsubscribed for the company.",
      },
      departments: {
        subscribed: "Course subscribed for the selected department.",
        unsubscribed: "Course unsubscribed for the selected department.",
      },
    },
  },
};
